import ss from "./styles.module.css";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";
import { pageNotFound, interviewStatus } from "../../../assets/images";
import { trackGoogleAnalyticsActionEvent } from "../../../utils/googleAnalytics";
import { GoogleEvents } from "../../../constants/enums";
import { Logo } from "../../../components/Logo";
import { texts } from "../../../constants/texts";

export const ErrorBoundary = () => {
  const { INTERRUPTED_RELOADING_DESCRIPTION, ALREADY_ATTENDED_DESCRIPTION } = texts
  let error: any = useRouteError();
  let title = "Whoops! We can't find that page.";
  let message = 'The link you clicked may be broken or the page has been removed.';
  let img = pageNotFound;

  if (isRouteErrorResponse(error) && error.status === 401) {
    trackGoogleAnalyticsActionEvent(GoogleEvents.interruptions, { reason: "Link Expired", page: "Interview" });
    title = 'Link Expired!';
    message = 'Dear candidate, this interview link is expired, please try to reschedule the interview';
  }

  if (error?.data?.data?.status === 400) {
    message = (error?.data?.data?.message === "Interview over") ? ALREADY_ATTENDED_DESCRIPTION : INTERRUPTED_RELOADING_DESCRIPTION;
    title = error?.data?.data?.message;
    img = interviewStatus;
  };

  return (
    <div className={ss.container}>
      <Logo />
      <div className={ss["content-box"]}>
        <img src={img} alt="thumbsup_image" />
        <h3>{title}</h3>
        <label>{message}</label>
      </div>
    </div>
  );
}
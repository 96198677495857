import { candidateInterviewRoute } from "./candidateInterview";
import { candidateWelcomeRoute } from "./candidateWelcome";
import { interviewCompleteRoute } from "./interviewComplete";
import { interviewFeedbackRoute } from "./interviewFeedback";
import { interviewStatusRoute } from "./interviewStatus";
import { errorRoute } from "./error";
import { interviewDetailsRoute } from "./interviewDetails";
import {interviewSessionTerminatedRoute} from './interviewSessionTerminated'

export const routes = {
    candidateWelcome: candidateWelcomeRoute,
    candidateInterview: candidateInterviewRoute,
    interviewFeedback: interviewFeedbackRoute,
    interviewComplete: interviewCompleteRoute,
    interviewStatus: interviewStatusRoute,
    error: errorRoute,
    interviewDetails: interviewDetailsRoute,
    interviewSessionTerminated: interviewSessionTerminatedRoute
}
import { isRouteErrorResponse, useRouteError } from "react-router-dom";
import { warningGrey } from "../../../assets/icons";
import { trackGoogleAnalyticsActionEvent } from "../../../utils/googleAnalytics";
import { GoogleEvents } from "../../../constants/enums";
import ss from "./styles.module.css";

export const ErrorBoundary = () => {
    let error = useRouteError();

    let title = 'Oops something went wrong!';
    let message = 'Please come back again later';

    if (isRouteErrorResponse(error) && error.status === 401) {
    trackGoogleAnalyticsActionEvent(GoogleEvents.interruptions, {reason: "Link Expired", page:"Welcome Page"});
      title = 'Link Expired!';
      message = 'Dear candidate, this interview link is expired, please try to reschedule the interview';
    }

    return (
      <div className={ss.container}>
        <img src={warningGrey} alt="warning_icon" />
        <div className={ss["text-container"]}>
          <h3>{title}</h3>
          <label>{message}</label>
        </div>
      </div>
    );
};
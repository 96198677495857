import { createBrowserRouter, redirect } from "react-router-dom";
import { ErrorBoundary } from "../pages/Root/ErrorBoundary";
import { appRoutes } from "../constants/appRoutes";
import { routes } from "./routes";
import { handleLogin, injectAuthGuard } from "./helper";
import { localStore } from "../stores/localStore";

injectAuthGuard(routes);

export const router = createBrowserRouter([
  {
    path: appRoutes.root.path,
    loader: async ({ request }) => {
      const position = request.url.indexOf("/", 9);
      const path = request.url.substring(position + 1);
      if (!path) {
        return redirect(appRoutes.error.path);
      }
      //works when evalgator sso redirects after login
      if (request.url.includes("code")) {
        const interviewId = localStore.interviewInfo.getValue()?.interviewId ?? '';
        //works on redirect from evalgator SSO
        const shouldRedirect = await handleLogin(request.url)
        if (shouldRedirect) return redirect(appRoutes.interviewDetails.path.replace(":interviewId", interviewId));
      }
      return true;
    },
    lazy: () => import("../pages/Root"),
    ErrorBoundary: ErrorBoundary,
    children: [
      // CANDIDATE - ATTEND INTERVIEW
      routes.candidateWelcome,
      routes.candidateInterview,
      routes.interviewFeedback,
      routes.interviewComplete,
      routes.interviewStatus,
      routes.interviewSessionTerminated,
      // ERROR PAGE
      routes.error,
      //INTERVIEW DETAILS
      routes.interviewDetails,
    ],
  },
  {
    path: "*",
    loader: () => redirect(appRoutes.error.path),
  },
]);

export const texts = {
    //Login 
    COPYRIGHT: "Evalgator 2024. All Rights Reserved",
    ENTER_PASSWORD: "Enter your password to proceed",
    SIGN_IN: "Sign In",
    CONTINUE_TO: "to continue to Screener",
    START_INTERVIEW: "Start Interview",
    // video recording
    ERR_NO_RECORD_SUPPORT: "Your browser doesn't support video recording. Please use latest version of the browser.",
    //welcome screen
    WEBCAM_WARNING: "You will not be able to attend the interview unless the access to audio and video is granted.",
    RULES_SUB_HEADING: "Please read and understand the rules & regulations properly before attending the assessment. Those who are not following the same will lose their chance to attend the assessment.",
    MINUTES: "Minutes",
    SECONDS: "Seconds",
    INTERVIEW_START_LABEL: "Interview starts in",
    CANCEL: "Cancel",
    ACCEPT: "Accept & Continue",
    WEBCAM_INSTRUCTION: "Interview require your audio & video response",
    RULES_TAB: "Assessment Rules & Regulations",
    VIDEO_AUDIO: "Check Audio & Video",
    DONT_REFRESH: "Do not refresh the page during the interview.",
    POWERED_BY: "Powered by",
    NO_VIDEO_AUDIO: "No Webcam or Microphone Access!",
    NO_VIDEO_AUDIO_DESC: "We cannot access your webcam or microphone. Please click 'Allow' to give webcam and microphone permission. Refresh the page or look for the lock icon in the addressbar to get the browser permissions incase you denied or didn't see the popup.",
    NO_WEBCAM: "Oops... No Webcam Detected",
    NO_WEBCAM_DESC: " Try plugging in a webcam into your computer now.If you are using an integrated camera, make sure that is turned on.",
    //interview screen
    INTERVIEW_WISH_LUCK: "All the best for your interview!",
    STOP_INTERVIEW: "Finish Interview",
    ERROR_CODE: "ERR_NETWORK",
    TAB_SWITCH: "Stay Focused!",
    TAB_SWITCH_DESC: "We recommend you stay focused on this interview as it may affect your overall performance analysis.",
    //Interview status screen
    INTERRUPTED_HEADER: "Your interview has ended earlier than expected",
    INTERRUPTED_RELOADING_DESCRIPTION: " We are sorry to inform you that the interview has ended due to repeated reloading of page. No worries, all the questions that you have answered has been recorded. Thank you for your cooperation.",
    INTERRUPTED_DESCRIPTION: " We are sorry to inform you that the interview has ended due to poor network connection. No worries, all the questions that you have answered has been recorded. Thank you for your cooperation.",
    CANCELLED_HEADER: "Interview cancelled",
    CANCELLED_DESCRIPTION: "The interview has been cancelled by our team. Please contact our team for further assistance.",
    ALREADY_ATTENDED_HEADER: "Interview already attended",
    ALREADY_ATTENDED_DESCRIPTION: "We appreciate your prompt participation! It appears that you have already completed the interview process. In case of any concerns or if you need further assistance, please feel free to reach out to our team.",
    //feedback screen
    FEEDBACK_RATING_MESSAGE: "Please provide rating before submitting",
    FEEDBACK_MESSAGE: "Please provide feedback before submitting",
    JUST_A_STEP: "Just a step more",
    ONE_MORE_STEP: "You have one more step to complete the interview.",
    HELP_US: "Help us get better!",
    HELP_US_INSTRUCTION: "We would like to hear about the experience you had with the interview. Please take a moment to share your thoughts and comments below. ",
    RATE_EXP: "Rate your overall experience",
    CARE_TO_SHARE: "Care to share more about it?",
    SUBMIT_FEEDBACK: "Submit Response",
    //Candidate recordings screen
    CANDIDATE: "Candidate",
    EXPERIENCE: "Experience",
    JOB_ROLE: "Job Role",
    RATING: "Rating",
    FEEDBACK: "Feedback",
    RATING_FEEDBACK: "Ratings and Feedback",
    SCORE_FEEDBACK: "Score & Feedback",
    SYSTEM_SCORE: "System Score",
    EVALUATOR_SCORE: "Evaluator Score",
    TOTAL_SCORE: "Total Score",
    //interview details screen
    INTERVIEW_VIDEO: "Interview video",
    INTERVIEW_TRANSCRIPT: "Interview transcript",
    QUESTIONS_ASKED: "Questions asked",
    //Evaluation modal
    EVALUATION: "Evaluation",
    SCORECARD: "Score Card",
    NO_FEEDBACK: "Feedback message not provided",
    INVALID_SCORE_MESSAGE: "Please enter valid scores (between 0 and 100) for all categories",
    SYSTEM_SCORE_UNAVAILABLE: "Please wait for system evaluation",
    EVALUATION_SUCCESS: "Interview feedback updated",
    //Score Details modal
    SCORE_DETAILS: "Score Details",
    //Academic details
    ACADEMIC_DETAILS: "Academic Details",
    COLLEGE: "College",
    PASS_YEAR: "Year of Passing",
    HIGH_SCHOOL: "High School Education Board",
    HIGHER_SEC: "Higher Secondary Education Board",
    BRANCH: "Branch",
    DEGREE_MARK: "Degree Mark",
    HIGH_SCHOOL_MARK: "High School Mark",
    HIGHER_SEC_MARK: "Higher Secondary School Mark",
    //Interview Details
    RESUME_DETAILS: "Resume details",
    INTERVIEW_DETAILS: "Interview details",
    PHONE_NUMBER: "Phone Number",
    CANCELLED_ON_LABEL: "This interview was cancelled",
    CANDIDATE_INFO: "Candidate Info",
    SCHEDULED_ON: "Scheduled on",
    // Messages
    ERROR_HEADER: "Whoops! We can't find that page.",
    ERROR_DESC: "The link you clicked may be broken or the page has been removed",
    SESSION_OVER_HEADER: "Session Terminated!!!",
    SESSION_OVER_DESC: "The session was terminated because the interview is currently active or ongoing in another session.",
}
import { localStore } from "../stores/localStore";
import { IAuthGuard } from "../utils/authGuard";
import { constants } from "./constants";

const { userRoles } = constants;

export interface IAppRoutesConfigValue {
  id?: string;
  path: string;
  relativePath?: string;
  authConfig?: IAuthGuard & {
    redirectUrl?: string | Function
  }
}

export const appRoutes = {
  root: {
    path: "/",
  },
  error: {
    path: "/error",
  },
  candidateWelcome: {
    path: "/candidate-welcome/:token",
  },
  get candidateInterview() {
    return {
      path: "/candidate-interview",
      authConfig: {
        roles: [userRoles.CANDIDATE],
        // pageRefresh: false,
        // redirectUrl: () => this.candidateWelcome.path.replace(':token', localStore.auth.getValue()?.token ?? ''),/
      },
    };
  },
  get interviewFeedback() {
    return {
      path: "/interview-feedback",
      authConfig: {
        roles: [userRoles.CANDIDATE],
        // pageRefresh: false,
        // redirectUrl: () =>
        //   this.candidateWelcome.path.replace(
        //     ":token",
        //     localStore.auth.getValue()?.token ?? ""
        //   ),
      },
    };
  },
  get interviewComplete() {
    return {
      path: "/interview-complete",
      authConfig: {
        roles: [userRoles.CANDIDATE],
        // pageRefresh: false,
        // redirectUrl: () =>
        //   this.candidateWelcome.path.replace(
        //     ":token",
        //     localStore.auth.getValue()?.token ?? ""
        //   ),
      },
    };
  },
  interviewStatus: {
    path: "/interview-status",
  },
  login: {
    path: "/login",
    // authConfig: {
    // roles: [userRoles.HR],
    // },
  },
  interviewDetails: {
    path: "/interview-details/:interviewId",
    authConfig: {
      // roles: [userRoles.HR],
    },
  },
  interviewSessionTerminated: {
    path: "/interview-session-terminated",
    authConfig: {
      // roles: [userRoles.HR],
    },
  },
} satisfies Record<string, IAppRoutesConfigValue>;

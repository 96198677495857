import { getSupportedAudioMime, getSupportedVideoMime } from "../utils/helper";

export const constants = {
  userRoles: {
    CANDIDATE: 'CANDIDATE' as const,
    ADMIN: 'ADMIN' as const,
    HR: 'HR' as const
  },
  ...getSupportedVideoMime(),
  ...getSupportedAudioMime()
}

export const GoogleAnalyticsScreen: { [key: string]: string } = {
  "candidate-welcome": "welcome_page",
  "candidate-interview": "interview_page",
  "interview-feedback": "feedback_page"
};

export const interviewStatuses = {
  CREATED: 0,
  SHORTLISTED: 1,
  INVITED: 2,
  SCHEDULED: 3,
  RESCHEDULED: 4,
  CANCELLED: 5,
  IN_PROGRESS: 6,
  COMPLETED: 7,
  EVALUATED: 8,
  ON_HOLD: 9,
  INTERRUPTED: 10,
};

export const EventsName = {
  TabChange : "TAB_CHANGE",
  Reload : "RELOAD",
  ButtonClick : "BUTTON_CLICK",
  VideoDark : "VIDEO_DARK",
  AutoTrigger : "AUTO_TRIGGER"
} as const;

export type EventType = typeof EventsName[keyof typeof EventsName];

export const BasicEventsProctoring = {
  InterviewSessionStarted : "Interview Session Started",
  StartedInterview : "Interview Started",
  NextInterviewClick : "Next Question Clicked",
  AutoAdvanceNextQuestion : "Auto-Advance Next Question",
  DisconnectedAndReloaded : "Page Reloaded",
  NetworkDisconnected : "Network Disconnected",
  TabOut : "Switched Tabs Out",
  TabIn : "Returned to Interview Tab",
  CameraCovered : "Camera Covered",
  CameraUncovered : "Camera Uncovered",
  FinishInterviewClick : "Finish Interview Clicked",
  StopInterviewCancelled : "Stop Interview Canceled",
  StopInterviewConfirmed : "Stop Interview Confirmed",
  AutoSubmittedInterview : "Interview Auto-Submitted",
  FinishedInterview : "Interview Finished",
  TriedSubmittingEmptyFeedback : "Empty Feedback Attempt",
  FeedbackSubmitted : "Feedback Submitted",
  InterviewFinished : "Interview Session Ended"
} as const;

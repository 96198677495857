export const apiUrls = {
    tokenRefresh: '/auth/refresh/',
    verifyInterviewUrl: "/api/v1/interviews/get-interview",
    startInterview: "/api/v1/interviews/process/:interviewId",
    getInterviewQuestion: "/api/v1/interviews/process/:interviewId/",
    submitQuestionResponse: "/api/v1/transcribe/",
    submitEventTriggers: "/api/v1/interviews/event/:interviewId/",
    finishInterview: "/api/v1/interviews/submit/:interviewId/",
    submitInterviewFeedback: "/api/v1/interviews/feedback/:interviewId/",
    getInterviewToken:"/api/v1/interviews/verify-token/:interviewId/",
    login: "/auth/login/",
    getLoginUrl: "/auth/login/",
    getInterviewDetails: "/api/v1/interviews/:interviewId",
    interviewTranscript: "/api/v1/interviews/transcript/:interviewId/",
    sessionCheck: "/api/v1/interviews/beat/",
    sessionCreate:"/api/v1/interviews/session/create/"
}